/** @format */

import { useStyles } from "./UploadFileModalStyle";
import { useState, useEffect, useRef } from "react";
import ActionButton from "components/ActionButton/ActionButton";
import csvTemplateForPassword from "assets/files/employee_import_template_v2.xlsx";
import csvTemplateForEmployeeId from "assets/files/employee_import_template_with_id.xlsx";
import { detectEncodingFailure, validatePhone } from "Utilities/validator";
import { isEmpty } from "lodash";
import { RegistrationTimingType } from "types/registrationTimingType";
import { ApiScheduledUserRegistrations } from "Apis/ApiScheduledUserRegistrations";
import { AuthType } from "types/authType";
import { ENCODING_TYPES } from "config/constant";

interface UploadFileModalProps {
  show: boolean;
  authType: AuthType;
  onClose: () => void;
  action: (data: any) => void;
}
export default function UploadFileModal({
  show,
  authType,
  onClose,
  action,
}: UploadFileModalProps) {
  const classes = useStyles();

  const [showStatus, setShowStatus] = useState(show);
  const [csvFile, setCsvFile] = useState(null);
  const [timing, setTiming] = useState<RegistrationTimingType>("now");

  const initialScheduledAt = new Date();
  initialScheduledAt.setMinutes(
    initialScheduledAt.getMinutes() + (60 - initialScheduledAt.getMinutes())
  );
  initialScheduledAt.setSeconds(0);
  initialScheduledAt.setMilliseconds(0);
  const [scheduledAt, setScheduledAt] = useState<Date>(initialScheduledAt);
  const [schedules, setSchedules] = useState<any>([]);

  const rootRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
      ApiScheduledUserRegistrations()
        .then((res) => {
          setSchedules(res["result-object"]);
        })
        .catch((err) => {
          console.log("[ApiScheduledUserRegistrations] err:", err);
        });
    } else {
      document.body.style.overflow = "auto";
    }
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: any) {
      if (rootRef && rootRef.current && contentRef && contentRef.current) {
        const root: any = rootRef.current;
        const content: any = contentRef.current;
        if (root.contains(e.target) && !content.contains(e.target)) {
          onClose();
        }
      }
    }
  }, [rootRef, contentRef, show]);

  useEffect(() => {
    setShowStatus(show);
  }, [show]);

  const onFileInputChange = (e: any) => {
    setCsvFile(e.target.files[0]);
  };

  const [errMsg, setErrMsg] = useState<Array<String>>([]);

  const uploadPasswordUser = async () => {
    let rawUserList: any = [];
    let errs: any = [];

    if (csvFile !== null) {
      let result: string | null = null;
      for (const encoding of ENCODING_TYPES) {
        result = await new Promise((resolve) => {
          var reader = new FileReader();
          reader.onload = (f) => {
            resolve(reader.result as string);
          };
          reader.onerror = function (e) {
            resolve(reader.result as string);
          };
          reader.readAsText(csvFile, encoding);
        });
        console.log(`result with ${encoding}:`, result);
        if (result && !detectEncodingFailure(result)) {
          break; // 正常に読み込めた場合はループを抜ける
        }
      }
      if (result !== null) {
        rawUserList = parseCsv(result, ",");
      } else {
        errs.push(
          "ファイルを読み込めませんでした。（ファイルを更新した場合は、一度キャンセルボタンを押してください）"
        );
      }
    } else {
      errs.push("ファイルが選択されていません。");
    }

    if (timing === "reservation" && !validateSchedule(scheduledAt)) {
      errs.push("現在時刻よりも後の日時を指定してください。");
    }

    rawUserList.splice(0, 1);
    let userList = rawUserList.filter(
      (row: any) => !isEmpty(row[0]) && !isEmpty(row[1])
    );

    let inputProps: any = {
      userList,
      timing,
      scheduledAt,
    };

    if (errs.length > 0) {
    } else if (userList.length > 0) {
      if (userList.length <= 1000) {
        userList.forEach(function (row: any, i: any) {
          var j = i + 2;
          if (row[0] === void 0 || row[0].length == 0) {
            errs.push(j + "行目 氏名が入力されていません。");
          }

          if (row[1] === void 0 || row[1].length == 0) {
            errs.push(j + "行目 会社メールアドレスが入力されていません。");
          } else {
            if (!/^[^@]+@[^@.]+.[^@]+$/.test(row[1])) {
              errs.push(
                j + "行目 会社メールアドレスの形式が正しくありません。"
              );
            }
          }
        });
      } else {
        errs.push("1度に登録できるデータは1000件までです。");
      }
    } else {
      errs.push("データがありません。");
    }

    setErrMsg([]);
    if (errs.length > 0) {
      let temp = errs;
      setErrMsg(temp);
    } else {
      console.log("run action 1");
      action(inputProps);
    }
  };

  const uploadEmployeeIdUser = async () => {
    let rawUserList: any = [];
    let errs: any = [];

    if (csvFile !== null) {
      let result: string | null = null;
      for (const encoding of ENCODING_TYPES) {
        result = await new Promise((resolve) => {
          var reader = new FileReader();
          reader.onload = (f) => {
            resolve(reader.result as string);
          };
          reader.onerror = function (e) {
            resolve(reader.result as string);
          };
          reader.readAsText(csvFile, encoding);
        });
        console.log(`result with ${encoding}:`, result);
        if (result && !detectEncodingFailure(result)) {
          break; // 正常に読み込めた場合はループを抜ける
        }
      }
      if (result) {
        rawUserList = parseCsv(result, ",");
      } else {
        errs.push(
          "ファイルを読み込めませんでした。（ファイルを更新した場合は、一度キャンセルボタンを押してください）"
        );
      }
    } else {
      errs.push("ファイルが選択されていません。");
    }

    if (timing === "reservation" && !validateSchedule(scheduledAt)) {
      errs.push("現在時刻よりも未来の日時を指定してください。");
    }

    rawUserList.splice(0, 1);
    let userList = rawUserList.filter(
      (row: any) => !isEmpty(row[0]) && !isEmpty(row[1])
    );

    let inputProps: any = {
      userList,
      timing,
      scheduledAt,
    };

    if (errs.length > 0) {
    } else if (userList.length > 0) {
      if (userList.length <= 1000) {
        userList.forEach(function (row: any, i: any) {
          const fullName = row[0];
          let employeeId = row[1];

          if (isEmpty(fullName) && isEmpty(employeeId)) {
            return;
          }

          var j = i + 1;
          if (fullName === void 0 || fullName.length == 0) {
            errs.push(j + "行目 氏名が入力されていません。");
          }

          if (employeeId === void 0 || employeeId.length == 0) {
            errs.push(j + "行目 従業員番号が入力されていません。");
          }
        });
      } else {
        errs.push("1度に登録できるデータは1000件までです。");
      }
    } else {
      errs.push("データがありません。");
    }

    setErrMsg([]);
    if (errs.length > 0) {
      let temp = errs;
      setErrMsg(temp);
    } else {
      console.log("run action 2");
      action(inputProps);
    }
  };

  //--------------parseCsv----------
  function parseCsv(csvStr: any, delimiter: any) {
    var rowRegex = /(?:(?:"[^"]*")*[^\r\n"]*)+/g,
      colRegex = new RegExp('(?:(?:"[^"]*")*[^' + delimiter + '"]*)+', "g"),
      rows = [],
      row,
      cells,
      cell,
      rowMaches,
      colMaches;
    while ((rowMaches = rowRegex.exec(csvStr)) !== null) {
      if (rowMaches[0] !== "") {
        cells = [];
        row = rowMaches[0];
        //セルを切り出す
        while ((colMaches = colRegex.exec(row)) !== null) {
          cell = colMaches[0].replace(/^\s+|\s+$/g, "");
          if (cell.charAt(0) == '"' && cell.charAt(cell.length - 1) == '"') {
            cell = cell.slice(1, -1);
          }
          cell = cell.replace(/""/g, '"');
          cells.push(cell);
          colRegex.lastIndex++; //一歩前へ！
        }
        rows.push(cells);
      }
      rowRegex.lastIndex++; //一歩前へ！
    }
    return rows;
  }
  //--------------parseCsv----------

  const handleTiming = (e: any) => {
    setTiming(e.target.value);
  };

  const handleScheduledAt = (e: any) => {
    const localeDate = scheduledAt.toLocaleDateString("ja-JP");
    const localeTime = scheduledAt.toLocaleTimeString("ja-JP");
    const year = localeDate.split("/")[0];
    const month = localeDate.split("/")[1].padStart(2, "0");
    const day = localeDate.split("/")[2].padStart(2, "0");
    const hours = localeTime.split(":")[0];
    const value = e.target.value;

    if (e.target.type === "date") {
      if (value === "") {
        return;
      }
      const [newYear, newMonth, newDay] = value
        .split("-")
        .map((num: string) => parseInt(num));
      setScheduledAt(
        new Date(
          newYear,
          newMonth - 1, // jsの仕様で月は0から始まる
          newDay,
          parseInt(hours),
          0
        )
      );
    } else if (e.target.type === "number") {
      let newHours = parseInt(value);
      if (isNaN(newHours)) {
        newHours = 0;
      }
      if (value >= 24) {
        return;
      }
      setScheduledAt(
        new Date(
          parseInt(year),
          parseInt(month) - 1, // jsの仕様で月は0から始まる
          parseInt(day),
          newHours,
          0
        )
      );
    }
  };

  const validateSchedule = (datetime: Date) => {
    const now = new Date();
    return datetime > now;
  };

  const getDate = (datetime: Date) => {
    const localeDate = datetime.toLocaleDateString("ja-JP");
    const [year, month, day] = localeDate.split("/");
    const formattedMonth = month.padStart(2, "0");
    const formattedDay = day.padStart(2, "0");
    return `${year}-${formattedMonth}-${formattedDay}`;
  };
  const getTime = (datetime: Date) => {
    const localeTime = datetime.toLocaleTimeString("ja-JP");
    const [hours] = localeTime.split(":");
    return hours;
  };

  return (
    <>
      <div
        className={showStatus ? classes.loaderWrapper : classes.displayNone}
        ref={rootRef}
      >
        <div className={classes.modalRoot} ref={contentRef}>
          <h2 className={classes.title}>ユーザー追加</h2>
          <div className={classes.descContainer}>
            <div className={classes.container}>
              <div className={classes.descDetail}>
                <h3 className={classes.descDetailTitle}>登録方法</h3>
                (1) テンプレートのxslxファイルをダウンロード
                <br />
                (2) Excelの2行目から従業員情報を登録
                <br />
                (3) Excelをcsvファイル（CSV UTF-8
                コンマ区切り）で保存して、ここへアップロード
              </div>
              <div className={classes.formContainer}>
                <div className={classes.mainContainerFlex}>
                  <div className={classes.mainContainer}>
                    <div className={classes.form}>
                      <div className={classes.formTitle}>
                        ① 従業員情報をアップロードする
                      </div>
                      <input
                        type="file"
                        accept="text/csv"
                        onChange={(e) => onFileInputChange(e)}
                        className={classes.fileInput}
                      />
                    </div>
                  </div>
                  <div className={classes.mainContainer}>
                    <div className={classes.form}>
                      <div className={classes.formTitle}>テンプレート</div>
                      <div>
                        <a
                          href={
                            authType === "password"
                              ? csvTemplateForPassword
                              : authType === "employee_id"
                                ? csvTemplateForEmployeeId
                                : csvTemplateForPassword
                          }
                          download="福利アプリ_従業員一括登録用.xlsx"
                          className={classes.smallLink}
                        >
                          ダウンロード
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.note}>
                  <div>※</div>
                  <div>
                    100名以上の送付予約をした場合、送信完了までに数分かかることがございます。
                    <br />
                    送付処理自体は稼働しておりますので、ご安心くださいませ。
                  </div>
                </div>
                <div>
                  <div className={classes.formTitle}>② 登録方法を選択する</div>
                  <div>
                    <div>
                      <input
                        defaultChecked
                        type="radio"
                        name="timing"
                        value="now"
                        id="now"
                        onChange={handleTiming}
                      />
                      <label className={classes.radioLabel} htmlFor="now">
                        今すぐ登録する
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="timing"
                        value="reservation"
                        id="reservation"
                        onChange={handleTiming}
                      />
                      <label
                        className={classes.radioLabel}
                        htmlFor="reservation"
                      >
                        登録予約をする
                      </label>
                      <br />
                      <div className={classes.scheduleContainer}>
                        <input
                          type="date"
                          className={classes.scheduleInputDate}
                          min={new Date().toISOString().split("T")[0]}
                          value={getDate(scheduledAt)}
                          onChange={handleScheduledAt}
                          disabled={timing === "now"}
                        />
                        <input
                          type="number"
                          step={1}
                          min={0}
                          max={23}
                          className={classes.scheduleInputTime}
                          value={getTime(scheduledAt)}
                          onChange={handleScheduledAt}
                          disabled={timing === "now"}
                        />
                        時
                      </div>
                    </div>
                  </div>
                  <div className={classes.errorMessage}>
                    {errMsg.length > 0 ? (
                      <>
                        <div>入力エラー</div>
                        <ul>
                          {errMsg.map((item: any, key: any) => {
                            return <li key={key}>{item}</li>;
                          })}
                        </ul>
                      </>
                    ) : null}
                  </div>
                  <div className={classes.btnContainer}>
                    <ActionButton
                      content="キャンセル"
                      className={classes.cancelBtn}
                      action={onClose}
                    />
                    <ActionButton
                      content="登録する"
                      type="dark"
                      className={classes.confirmBtn}
                      action={
                        authType === "password"
                          ? uploadPasswordUser
                          : uploadEmployeeIdUser
                      }
                    />
                  </div>
                </div>
              </div>
              {schedules.length > 0 && (
                <>
                  <p className={classes.scheduleTitle}>送信予約状況</p>
                  <div className={classes.scheduleTable}>
                    {schedules.map((schedule: any, index: number) => {
                      return (
                        <div
                          className={classes.scheduleRow}
                          key={"schedule" + index}
                        >
                          <div>{schedule.targets.length}名分の登録予約中</div>
                          <div>
                            送信予定日：
                            {new Date(schedule.scheduled_at).toLocaleString(
                              "ja-JP"
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {/* <div className={clsx(classes.descDetail, classes.beforeDownload)}>
              <strong>■ インポート用ファイル</strong><br />
              以下のファイルはCSVインポートのサンプルファイルです。ダウンロードしてご利用ください。
            </div>
            <div className={classes.downloadBig}><a href={csvSample} download>CSVインポートサンプル.csv</a></div>
            <div className={classes.downloadSmall}>
              <div className={classes.fileSize}>1KB・</div><div className={classes.smallLink}><a href={csvSample} download>Download</a></div>
            </div> */}
              {/* <div className={classes.descDetail}>
              <strong>■ 各列の入力内容</strong><br />
              左から、姓,名,電話番号（ハイフン有り）,会社メールアドレス,個人メールアドレス（空欄可）,パスワード
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
